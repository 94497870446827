.chase-loader {
  text-align: center;
  padding: 20px 0;

  h3 {
    padding: 10px 0 0;
    font-size: 18px;
  }
}

.wpwl-form {
  max-width: 100%;
}

.wpwl-label {
  font-size: 14px;
  color: #6a707b;
  font-family: Graphik;
  display: block;
  float: none;
  width: 100%;
}

.wpwl-hint {
  font-size: 12px;

  &.wpwl-hint-cardNumberError, &.wpwl-hint-expiryMonthError {
    color: rgb(240, 102, 102);
  }
}

.wpwl-wrapper-cardNumber, .wpwl-wrapper-cardHolder, .wpwl-wrapper-expiry, .wpwl-wrapper-cvv {
  width: 100%;
}

.theme-dark {
  .wpwl-wrapper {
    .wpwl-control {
      background: none;
      border: 1px solid #abaebc;

      input {
        background: none !important;
      }
    }
  }
}

.wpwl-group {
  margin-bottom: 7px;
}

.wpwl-group-expiry {
  float: left;
  width: 48%;
}

.wpwl-group-cvv {
  float: right;
  width: 48%;
}

.wpwl-group-cardHolder {
  clear: both;
}

.wpwl-group-submit {
  padding-top: 15px;
}

.wpwl-group-submit {
  display: none;
}

.wpwl-brand-card {
  display: none !important;
}