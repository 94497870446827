@import '~@malwarebytes/react-components/build/main.css';

:root {
  --header-heght: 56px;
  --footer-heght: 249px;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

.page {
  min-height: calc(100vh - var(--header-heght) - var(--footer-heght));
}

.list-left-padding {
  padding-left: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
span,
div {
  cursor: default;
}

/*put font a bit down, so its stay on center of button*/
.button-text-line-height {
  line-height: 1.4 !important;
}

/*Override browser autofill*/
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 5000s !important;
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #282828 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.light input:-webkit-autofill,
.light input:-webkit-autofill:hover,
.light input:-webkit-autofill:focus,
.light input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 5000s !important;
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #fff inset !important;
  -webkit-text-fill-color: #141519 !important;
}